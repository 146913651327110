import store from "@/store";
let updateStatusArray = [0, 1, 10, 11, 12];
let deleteStatusArray = [0, 9, 10, 11, 12];
let cancelStatusArray = [1, 2, 13];
let commitStatusArray = [0, 10, 11, 12];
let spanArr = [];
const noSpanPropertyArray = [
  "contactName",
  "contactPhone",
  "takeRemark",
  "outDriveTime",
  "arrivedTime",
  "loadGoodsTime",
  "giveAddressInfo.contactName",
  "giveAddressInfo.contactPhone",
];
export function showButton(row, type, auth) {
  let statusArray = [];
  // let auth = null
  switch (type) {
    case "commit":
      statusArray = commitStatusArray;
      // auth = 'order:customer:commit'
      break;
    case "update":
      statusArray = updateStatusArray;
      // auth = 'order:customer:update'
      break;
    case "delete":
      statusArray = deleteStatusArray;
      // auth = 'order:customer:delete'
      break;
    case "cancel":
      statusArray = cancelStatusArray;
      // auth = 'order:customer:cancel'
      break;
    default:
      break;
  }
  if (auth) {
    // 无权限
    if (!this.newAuth(auth)) {
      return false;
    }
  }
  return statusArray.indexOf(row.orderInfo.status) > -1;
}

// export function getSpanArr (data) {
//   spanArr = []
//   for (let i = 0; i < data.length; i++) {
//     if (i === 0) {
//       spanArr.push(1)
//       this.pos = 0
//     } else {
//       // 判断当前元素与上一个元素是否相同
//       if (data[i].orderInfo.id === data[i - 1].orderInfo.id) {
//         spanArr[this.pos] += 1
//         spanArr.push(0)
//       } else {
//         spanArr.push(1)
//         this.pos = i
//       }
//     }
//   }
// }

// export function arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
//   let property = column.property
//   // 状态合并校验
//   if (property === 'status') {
//     if (store.state.common.spanStatusArray.indexOf(row.orderInfo.status) > -1) {
//       const _row = spanArr[rowIndex]
//       const _col = _row > 0 ? 1 : 0
//       return {
//         rowspan: _row,
//         colspan: _col
//       }
//     }
//   } else if (noSpanPropertyArray.indexOf(property) === -1) {
//     const _row = spanArr[rowIndex]
//     const _col = _row > 0 ? 1 : 0
//     return {
//       rowspan: _row,
//       colspan: _col
//     }
//   }
// }

// 定义函数getValueByPath，用于根据给定的对象和属性路径变量获取对应属性值
function getValueByPath(obj, pathVariable) {
  const parts = pathVariable.split('.');
  // 递归处理函数
  const recursiveGetValue = (currentObj, remainingParts) => {
      if (currentObj === null || currentObj == undefined) {
          return null;
      }
      if (remainingParts.length === 0) {
          return currentObj;
      }
      const nextPart = remainingParts[0];

      return recursiveGetValue(currentObj[nextPart], remainingParts.slice(1));
  };
  return recursiveGetValue(obj, parts);
}


export function getSpanArr(data, columns = []) {
  let that = this;
  spanArr = [];
  for (let i = 0; i < data.length; i++) {
    if (i === 0) {
      spanArr.push({
        limit: 1,
        span: [],
      });
      that.pos = 0;
    } else {
      // 判断当前元素与上一个元素是否相同
      if (data[i].orderInfo.id === data[i - 1].orderInfo.id) {
        spanArr[that.pos] = {
          limit: (spanArr[that.pos].limit += 1),
          span: [],
        };
        if (columns) {
          spanArr.push({
            limit: 1,
            span: [],
          });
          columns.map((item) => {
            if (getValueByPath(data[i], item) !== getValueByPath(data[i - 1], item) ) {
              spanArr[that.pos].span.push(item);
              spanArr[i].limit = -1;
              spanArr[i].span.push(item);
            }
          });
        } else {
          spanArr.push({
            limit: 0,
            span: [],
          });
        }
      } else {
        spanArr.push({
          limit: 1,
          span: [],
        });
        that.pos = i;
      }
    }
  }
}
// 局部运用 comm中全局的方法暂不清楚其余地方的运用 去除一些没有合并的项
export function arraySpanMethod({ row, column, rowIndex, columnIndex }) {
  var noSpanPropertyArray = [];
  noSpanPropertyArray = noSpanPropertyArray.concat(spanArr[rowIndex].span);

  let property = column.property;
  // 状态合并校验
  //if (property === "status") {
  //  if (store.state.common.spanStatusArray.indexOf(row.orderInfo.status) > -1) {
  //    const _row = spanArr[rowIndex].limit;
  //    const _col = _row > 0 ? 1 : 0;
  //    return {
  //      rowspan: _row,
  //      colspan: _col,
  //    };
  //  }
  //} else
    if (noSpanPropertyArray.indexOf(property) === -1) {
    // 该行需要合并的单元格 返回对应的跨行数 spanArr[rowIndex].limit为-1是代表该行被合并 直接返回[0:0]
    const _row = spanArr[rowIndex].limit === -1 ? 0 : spanArr[rowIndex].limit;
    const _col = _row > 0 ? 1 : 0;
    return {
      rowspan: _row,
      colspan: _col,
    };
  } else {
    // 该行不需要合并的单元格 全部正常返回[1:1]
    return {
      rowspan: 1,
      colspan: 1,
    };
  }
}

// 表头部分样式
export function renderHeader(h, { column, $index }) {
  return h("div", {
    attrs: {
      class: "smallRow",
    },
    // domProps: {
    //   innerHTML: '<span class="red">*</span>' + column.label
    // }
    domProps: {
      innerHTML: column.label,
    },
  });
}

export function getDefaultValue(value, defaultValue) {
  if (value === undefined || value === null) {
    return defaultValue;
  }
  let number = Number(value);
  if (number === undefined || number === null) {
    return defaultValue;
  }
  if (isNaN(number)) {
    return 0;
  } else {
    return number;
  }
}

export function formattedDateTime(dateTime) {
  if (!dateTime) {
    return '';
  }
  const date = new Date(dateTime);
  const month = String(date.getMonth() + 1);
  const day = String(date.getDate());
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${month}/${day} ${hours}:${minutes}`;
}


export function formattedDateTimeHHmm(dateTime) {
  if (!dateTime) {
    return '';
  }
  const date = new Date(dateTime);
  const month = String(date.getMonth() + 1);
  const day = String(date.getDate());
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${hours}:${minutes}`;
}
